<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-8">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-7 col-sm-6">
            <form class="search-form" @submit.prevent="doSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
              </div>
            </form>
          </div>
          <div class="col-4 col-sm-3">
            <button v-if="checkPermission.create" class="btn btn-primary" @click="showModalGroup = true">Add Group</button>
          </div>
        </div>
        <p class="text-muted mt-3">Groups</p>
        <ul class="list-unstyled px-1">
          <li class="pr-1 mb-3" v-for="group in groupList" :key="group.id">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center justify-content-between pb-1 flex-grow border-bottom">
                <div>
                  <p class="text-body">{{ group.name }}</p>
                  <div class="d-flex align-items-center">
                    <p class="text-muted tx-13">{{ formatDate(group.created_at, "D MMM YYYY HH:mm") }}</p>
                  </div>
                </div>
                <div class="d-flex align-items-end">
                  <button v-if="checkPermission.read" class="btn btn-outline-primary btn-sm mr-2" @click="handlerViewBtn(group.id)">
                    <font-awesome icon="list"></font-awesome>
                  </button>
                  <button v-if="checkPermission.update" class="btn btn-outline-primary btn-sm" @click="showModalEditGroup = true; formEditGroup.id = group.id; formEditGroup.name = group.name; formEditGroup.workspace_id = group.workspace_id;">
                    <font-awesome icon="edit"/>
                  </button> &nbsp;
                  <button v-if="checkPermission.delete" :disabled="!group.can_deleted" class="btn btn-outline-primary btn-sm" @click="deleteGroup(group.id)">
                    <font-awesome icon="times"/>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        <b-pagination v-if="totalRow > 10" v-model="currentPage" :total-rows="totalRow" :per-page="10"/>
      </div>
    </div>
    <b-modal v-model="showModalGroup" title="Add Group" hide-footer>
      <b-form @submit.prevent="createGroup" @reset="closeModalGroup">
        <b-form-group
          id="group-name-group"
          label="Group Name:"
          label-for="group-name">
          <b-form-input
            id="group-name"
            type="text"
            required
            v-model="formGroup.name" />
        </b-form-group>

        <b-form-group
          id="workspace-group"
          label="Workspace:"
          label-for="workspace">
          <b-form-select
            id="workspace"
            disabled
            :value="activeWorkspace._id"
            :options="workspaces"
            required />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalEditGroup" title="Edit Group" hide-footer>
      <b-form @submit.prevent="updateGroup" @reset="closeModalEditGroup">
        <b-form-group
          id="group-name-group"
          label="Group Name:"
          label-for="group-name">
          <b-form-input
            id="group-name"
            type="text"
            required
            v-model="formEditGroup.name" />
        </b-form-group>

        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import groupsAPI from '../../../api/agentGroups';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'Groups',
  metaInfo: {
    title: 'Groups',
  },
  data() {
    return {
      loaderStack: 0,
      loader: null,
      userList: [],
      groupList: [],
      showModalGroup: false,
      showModalEditGroup: false,
      search: '',
      page: 1,
      totalRow: 0,
      formGroup: {
        name: '',
        workspace_id: '',
        company_id: this.$store.state.company.activeCompany.id,
      },
      formEditGroup: {
        id: '',
        name: '',
        workspace_id: '',
        company_id: this.$store.state.company.activeCompany.id,
      },
      company_id: this.$store.state.company.activeCompany.id,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getGroupList();
      },
    },
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  mounted() {
    this.getGroupList();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    doSearch() {
      this.page = 1;
      this.getGroupList();
    },
    pageChanged(page) {
      this.page = page;
      this.getGroupList();
    },
    async getGroupList() {
      this.showLoader();
      await groupsAPI.getList(this.activeWorkspace._id, this.company_id, this.page, this.search)
        .then((res) => {
          this.totalRow = res.data.total;
          this.groupList = res.data.rows;
        })
        .catch(() => {});
      this.hideLoader();
    },
    clearForm() {
      this.form = {
        user_id: '',
        workspace_id: '',
      };
      this.formEditGroup = {
        id: '',
        name: '',
      };
    },
    closeModalGroup() {
      this.showModalGroup = false;
      setTimeout(() => {
        this.clearForm();
      }, 500);
    },
    closeModalEditGroup() {
      this.showModalEditGroup = false;
      setTimeout(() => {
        this.clearForm();
      }, 500);
    },
    async createGroup() {
      this.showLoader();
      this.formGroup.workspace_id = this.activeWorkspace._id;
      this.formGroup.company_id = this.company_id;
      await groupsAPI.create(this.formGroup)
        .then(async (response) => {
          await popupErrorMessages(response);
          this.$message({
            message: this.$t('groups.success.add'),
            type: 'success',
          });
          this.page = 1;
          this.search = '';
          this.getGroupList();
          this.closeModalGroup();
        })
        .catch(() => {});
      this.hideLoader();
    },
    async updateGroup() {
      this.showLoader();
      this.formEditGroup.company_id = this.company_id;
      await groupsAPI.update(this.formEditGroup)
        .then(async (response) => {
          await popupErrorMessages(response);
          this.$message({
            message: this.$t('groups.success.edit'),
            type: 'success',
          });
          this.page = 1;
          this.search = '';
          this.getGroupList();
          this.closeModalEditGroup();
        })
        .catch(() => {});
      this.hideLoader();
    },
/* eslint-disable */
    async deleteGroup(id) {
      this.$confirm(this.$t('groups.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            this.$message('This feature is coming soon.');
            // instance.confirmButtonLoading = true;

            // await groupsAPI.delete(id)
            //   .catch(() => {});
            // this.$message({
            //   message: this.$t('groups.success.delete'),
            //   type: 'success',
            // });

            // this.page = 1;
            // this.search = '';
            // this.getGroupList();
            // instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    formatDate(string, format = 'YYYY-MM-DD HH:mm:ss') {
      return moment(string).format(format);
    },
    handlerViewBtn(id) {
      this.$router.push({
        name: 'Groups - Members',
        params: {
          id,
        },
      });
    },
  },
};
</script>
